header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #009743;
  border-bottom: 1px solid #000;
}

.logo-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  max-height: 60px;
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .logo-container {
    padding: 0 20px;
  }
}
