@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto:wght@100&display=swap');

html {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
}