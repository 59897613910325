.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.contact {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  background-color: #009743;
  border-radius: 25px;
}

.contact div {
  margin: 1em;
}

.contact-info h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.contact-info h3 {
  font-size: 24px;
}

.contact-info p {
  font-size: 18px;
  margin-bottom: 15px;
  text-align: left;
}

.contact-info iframe {
  margin-bottom: 3rem;
}

.contact-form h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.contact-form h4 {
  font-size: 18px;
  margin-bottom: 30px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form div {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.contact-form span {
  color: red;
}

.contact-form button {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #f60;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #f90;
}
