footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  border: 1px solid #000;
}

.footer-logo {
  width: 200px;
  margin-bottom: 20px;
}

p {
  text-align: center;
  margin: 20px;
  font-size: 14px;
}

.fb-logo {
  width: 40px;
}

@media (min-width: 768px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo {
    margin-bottom: 0;
  }
  
  p {
    margin-bottom: 0;
    font-size: 16px;
  }
}